import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Profile from "./pages/Profile/Profile";
import Login from "./pages/Login/Login";
import Register from "./pages/Register/Register";
import History from "./pages/History/History";
import Admin from "./pages/Admin/Admin";
import { useContext } from "react";
import { Context } from "./context/Context";
import Rank from './pages/Ranking/Rank'
import AdminUsers from "./pages/AdminUsers/AdminUsers";

const App = () => {
  const { user } = useContext(Context);
  return (
    <>
      <Router basename="/app">
        <Switch>
          <Route exact path="/admin">
            {user?.login === "Rafał" ? <Admin /> : <Redirect to="/error" />}
          </Route>
          <Route path="/admin/users">
            {user?.login === "Rafał" ? <AdminUsers /> : <Redirect to="/error" />}
          </Route>
          <Route path="/profile">
            {user ? <Profile /> : <Redirect to="/login" />}
          </Route>
          <Route path="/login">
            {user ? <Redirect to="/profile" /> : <Login />}
          </Route>
          <Route path="/register">
            <Register />
          </Route>
          <Route path="/rank">
            <Rank />
          </Route>
          <Route path="/history">
            {user ? <History /> : <Redirect to="/login" />}
          </Route>
          <Route path="*">
            <Redirect to="/profile" />
          </Route>
        </Switch>
      </Router>
    </>
  );
};

export default App;
