import React, { useContext, useEffect, useRef, useState } from "react";
import "./login.css";
import { Person, Lock } from "@material-ui/icons";
import { CircularProgress } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import emailjs from "emailjs-com";
import axios from "axios";
import Swal from "sweetalert2";
import { Context } from "../../context/Context";
import Topbar from "../../components/Topbar/Topbar";
const Login = () => {
  const history = useHistory();
  const login = useRef();
  const password = useRef();
  const { loading, dispatch } = useContext(Context);
  const remindPassword = useRef();
  const [usersEmail, setUsersEmail] = useState([]);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const getUsers = async () => {
      try {
        const res = await axios.get(
          "https://noclegjura.pl/backend/api/users/all"
        );
        const data = res.data.map((user) => user.email);
        setUsersEmail(data);
      } catch (err) {
        console.log(err);
      }
    };
    getUsers();
  }, []);

  if (
    window.location.pathname ===
    `/app/login/${window.localStorage.getItem("password")}`
  ) {
    if (!user) {
      Swal.fire({
        icon: "success",
        title: "Zresetowano hasło",
        confirmButtonText: `Ok`,
        confirmButtonColor: "#c01820",
        denyButtonColor: "gray",
      }).then(() => {
        const getUser = async () => {
          try {
            const res = await axios.put(
              "https://noclegjura.pl/backend/api/users/email",
              {
                email: window.localStorage.getItem("email"),
                password: window.localStorage.getItem("password"),
                role: "user",
              }
            );
            setUser(res.data);
            history.push("/login");
            window.localStorage.removeItem("email");
            window.localStorage.removeItem("password");
          } catch (err) {
            console.log(err);
          }
        };
        getUser();
      });
    }
  }

  const handleForgotPassword = () => {
    Swal.fire({
      title: "Adres email powiązany z kontem",
      html: `<input type="email" id="email" class="swal2-input" placeholder="Wprowadź email.">`,
      confirmButtonText: "Zresetuj hasło",
      cancelButtonText: "Anuluj",
      showCancelButton: true,
      confirmButtonColor: "#c01820",
      focusConfirm: false,
      preConfirm: () => {
        const email = Swal.getPopup().querySelector("#email").value;
        if (!email) {
          Swal.showValidationMessage(`Wprowadź adres email`);
        }
        if (!email.includes("@")) {
          Swal.showValidationMessage(`Email powinien zawierać "@"`);
        }
        if (email.indexOf("@") === 0) {
          Swal.showValidationMessage(`Wprowadź coś przed "@"`);
        }
        if (!usersEmail.includes(email)) {
          Swal.showValidationMessage(`Podany adres email nie istnieje w bazie`);
        }
        return { email };
      },
    }).then((result) => {
      if (result.value?.email) {
        Swal.fire({
          icon: "success",
          title: "Zresetowano hasło",
          confirmButtonText: `Ok`,
          confirmButtonColor: "#c01820",
          denyButtonColor: "gray",
          footer: "Sprawdź skrzynkę pocztową",
        });
        const date = Math.floor(Math.random() * 1000);
        const password =
          `${result.value?.email[2]}${result.value?.email[4]}HXA${date}`.substr(
            0,
            8
          );
        window.localStorage.setItem("password", password);
        window.localStorage.setItem("email", result.value.email);
        const getUser = async () => {
          try {
            const res = await axios.post(
              "https://noclegjura.pl/backend/api/users/email",
              { email: result.value?.email }
            );
            const sendEmail = async () => {
              await emailjs.send(
                "service_6om6q9s",
                "template_1undqms",
                {
                  username: res.data.username,
                  surname: res.data.surname,
                  login: res.data.login,
                  password: password,
                  email: result.value?.email,
                },
                "user_MiVuI8DrOyolpGs9t82GM"
              );
            };
            sendEmail();
          } catch (err) {
            console.log(err);
          }
        };
        getUser();
      }
    });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    dispatch({ type: "LOGIN_START" });
    try {
      const res = await axios.post(
        "https://noclegjura.pl/backend/api/users/login",
        {
          login: login.current.value,
          password: password.current.value,
        }
      );
      Swal.fire({
        icon: "success",
        title: "Zalogowano",
        confirmButtonText: `Ok`,
        confirmButtonColor: "#c01820",
        denyButtonColor: "gray",
      }).then(() => {
        history.push("/profile");
      });
      const user = res.data;
      user.remind = remindPassword.current.checked;
      if (remindPassword.current.checked) {
        window.localStorage.setItem("user", JSON.stringify(user));
        dispatch({ type: "LOGIN_SUCCESS", payload: user });
      } else {
        window.sessionStorage.setItem("user", JSON.stringify(user));
        dispatch({ type: "LOGIN_SUCCESS", payload: user });
      }
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Nieprawidłowe dane!",
        confirmButtonText: `Cofnij`,
        confirmButtonColor: "#c01820",
        denyButtonColor: "gray",
      });
      dispatch({ type: "LOGIN_FAILURE" });
    }
  };
  return (
    <>
      <Topbar login={true} />
      <div className="login">
        <div className="loginWrapper">
          <div className="loginLeftImage">
            <img
              src={`${process.env.PUBLIC_URL}/assets/63dni_man.jpg`}
              alt=""
              className="loginlogoIMG"
            />
          </div>
          <div className="descLogin">
            <span className="descLoginText">Zaloguj do profilu</span>
          </div>
          <div className="loginRight">
            <form className="loginBox" onSubmit={handleLogin}>
              <div className="IconwithInput">
                <Person className="svgImage" />
                <input
                  placeholder="login..."
                  className="loginInput"
                  type="text"
                  required
                  ref={login}
                />
              </div>
              <div className="IconwithInput">
                <Lock className="svgImage" />
                <input
                  placeholder="password..."
                  className="loginInput"
                  type="password"
                  required
                  ref={password}
                />
              </div>
              <div className="checkboxPasswordRemind">
                <input
                  type="checkbox"
                  ref={remindPassword}
                  className="checkboxRemind"
                />
                <span style={{ marginLeft: "10px", color: "#6d6d6d" }}>
                  Zapamiętaj moje hasło
                </span>
              </div>
              <div className="loginBoxBottom">
                <button
                  type="submit"
                  className="loginButton"
                  disabled={loading}
                >
                  {loading ? (
                    <CircularProgress
                      className="CircularProgress"
                      size="20px"
                    />
                  ) : (
                    "Log In"
                  )}
                </button>
              </div>
            </form>
            <div className="link">
              <span className="aLinkLogin" onClick={handleForgotPassword}>
                Przypomnij hasło
              </span>
              lub
              <Link to="/register" className="aLinkLogin">
                Zarejestruj się
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
