import React, { useContext, useEffect, useState } from "react";
import "./profile.css";
import { CameraAlt } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import Ranking from "../../components/ranking/Ranking";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { useHistory } from "react-router";
import { Context } from "../../context/Context";
import axios from "axios";
import Topbar from "../../components/Topbar/Topbar";
const Profile = () => {
  const history = useHistory();
  const { user, dispatch } = useContext(Context);
  const [CurrentUser, setCurrentUser] = useState(user);
  const [users, setUsers] = useState([]);
  const [activities, setActivities] = useState([]);
  const [bieganieScore, setBieganie] = useState(0);
  const [chodzenieScore, setChodzenie] = useState(0);
  const [plywanieScore, setPlywanie] = useState(0);
  const [wozekScore, setWozek] = useState(0);
  const [kajakScore, setKajak] = useState(0);
  const [wiosloScore, setWioslo] = useState(0);
  const [rolkiScore, setRolki] = useState(0);
  const [totalScore, setTotal] = useState(0);
  const [rowerScore, setRower] = useState(0);
  const [dzieckoChodScore, setDzieckoChodScore] = useState(0);
  const [dzieckoBiegScore, setDzieckoBiegScore] = useState(0);
  const [differentScore, setDifferentScore] = useState(0);
  const today = new Date().toISOString().substr(0, 4);
  const age = today - user.age.substr(0, 4);
  const [file, setFile] = useState();

  useEffect(() => {
    const getActivity = async () => {
      try {
        const res = await axios.post(
          "https://noclegjura.pl/backend/api/activity/user",
          {
            login: CurrentUser.login,
            role: "user",
          }
        );
        setActivities(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    getActivity();
  }, [CurrentUser.login]);

  useEffect(() => {
    let score = 0;
    const total = activities.map((a) => Number(a.distance));
    for (let i = 0; i < total.length; i++) {
      score += total[i];
    }
    setTotal(score);
    const bieganie = activities.filter((a) => a.sport === "Bieg");
    if (bieganie.length > 0) {
      let score = 0;
      const scores = bieganie.map((b) => Number(b.distance));
      for (let i = 0; i < scores.length; i++) {
        score += scores[i];
      }
      setBieganie(score);
    }
    const dzieckoB = activities.filter(
      (a) => a.sport === "Bieg z wózkiem dziecięcym"
    );
    if (dzieckoB.length > 0) {
      let score = 0;
      const scores = dzieckoB.map((b) => Number(b.distance));
      for (let i = 0; i < scores.length; i++) {
        score += scores[i];
      }
      setDzieckoBiegScore(score);
    }
    const dzieckoC = activities.filter(
      (a) => a.sport === "Chód z wózkiem dziecięcym"
    );
    if (dzieckoC.length > 0) {
      let score = 0;
      const scores = dzieckoC.map((b) => Number(b.distance));
      for (let i = 0; i < scores.length; i++) {
        score += scores[i];
      }
      setDzieckoChodScore(score);
    }
    const diff = activities.filter((a) => a.sport === "Inne");
    if (differentScore.length > 0) {
      let score = 0;
      const scores = diff.map((b) => Number(b.distance));
      for (let i = 0; i < scores.length; i++) {
        score += scores[i];
      }
      setDifferentScore(score);
    }
    const chodzenie = activities.filter((a) => a.sport === "Chód");
    if (chodzenie.length > 0) {
      let score = 0;
      const scores = chodzenie.map((b) => Number(b.distance));
      for (let i = 0; i < scores.length; i++) {
        score += scores[i];
      }
      setChodzenie(score);
    }
    const plywanie = activities.filter((a) => a.sport === "Pływanie");
    if (plywanie.length > 0) {
      let score = 0;
      const scores = plywanie.map((b) => Number(b.distance));
      for (let i = 0; i < scores.length; i++) {
        score += scores[i];
      }
      setPlywanie(score);
    }
    const wozek = activities.filter(
      (a) => a.sport === "Jazda na wózku inwalidzkim"
    );
    if (wozek.length > 0) {
      let score = 0;
      const scores = wozek.map((b) => Number(b.distance));
      for (let i = 0; i < scores.length; i++) {
        score += scores[i];
      }
      setWozek(score);
    }
    const kajak = activities.filter((a) => a.sport === "Kajakarstwo");
    if (kajak.length > 0) {
      let score = 0;
      const scores = kajak.map((b) => Number(b.distance));
      for (let i = 0; i < scores.length; i++) {
        score += scores[i];
      }
      setKajak(score);
    }
    const wioslo = activities.filter((a) => a.sport === "Wioślarstwo");
    if (wioslo.length > 0) {
      let score = 0;
      const scores = wioslo.map((b) => Number(b.distance));
      for (let i = 0; i < scores.length; i++) {
        score += scores[i];
      }
      setWioslo(score);
    }
    const rower = activities.filter((a) => a.sport === "Jazda na rowerze");
    if (rower.length > 0) {
      let score = 0;
      const scores = rower.map((b) => Number(b.distance));
      for (let i = 0; i < scores.length; i++) {
        score += scores[i];
      }
      setRower(score);
    }
    const rolki = activities.filter((a) => a.sport === "Jazda na rolkach");
    if (rolki.length > 0) {
      let score = 0;
      const scores = rolki.map((b) => Number(b.distance));
      for (let i = 0; i < scores.length; i++) {
        score += scores[i];
      }
      setRolki(score);
    }
  }, [
    activities,
    CurrentUser._id,
    bieganieScore,
    chodzenieScore,
    rolkiScore,
    dzieckoBiegScore,
    dzieckoChodScore,
    differentScore,
    rowerScore,
    wozekScore,
    kajakScore,
    wiosloScore,
    plywanieScore,
    totalScore,
  ]);

  useEffect(() => {
    const getUsers = async () => {
      try {
        await axios.get("https://noclegjura.pl/backend/api/users/all");
        const resa = await axios.get(
          "https://noclegjura.pl/backend/api/users/all"
        );
        const sortedTotal = await resa.data
          .slice(0, resa.data.length)
          .sort((a, b) => {
            if (a.total > b.total) return -1;
            if (a.total < b.total) return 1;
            return 0;
          });
        setUsers(sortedTotal);
      } catch (err) {
        console.log(err);
      }
    };
    getUsers();
  }, [activities]);

  useEffect(() => {
    const getUser = async () => {
      try {
        const res = await axios.get(
          `https://noclegjura.pl/backend/api/users/user/${user._id}`
        );
        setCurrentUser(res.data);
        if (user.remind) {
          window.localStorage.setItem("user", JSON.stringify(res.data));
        } else {
          window.sessionStorage.setItem("user", JSON.stringify(res.data));
        }
      } catch (err) {
        console.log(err);
      }
    };
    getUser();
  }, [user._id, user.remind]);
  const handleEditProfile = () => {
    Swal.fire({
      title: "Edytuj Profil",
      html: `<input type="text" id="username" class="swal2-input" value=${CurrentUser.username}>
      <input type="text" id="surname" class="swal2-input" value=${CurrentUser.surname}>
      <input type="text" id="city" class="swal2-input" value=${CurrentUser.city}>
      <input type="text" id="coutry" class="swal2-input" value=${CurrentUser.coutry}>
      <input type="date" id="birthday" class="swal2-input" value=${CurrentUser.age}>
      <p style="position: absolute; left: 50%; transform: translateX(-50%);">Zmień hasło</p>
      <input type="password" id="password" class="swal2-input" style="margin-top: 50px" placeholder="nowe hasło">
      <input type="password" id="confirmPassword" class="swal2-input" placeholder="potwierdź hasło">
      `,
      confirmButtonText: "Zmień dane",
      confirmButtonColor: "#c01820",
      showCloseButton: true,
      focusConfirm: false,
      preConfirm: () => {
        const username = Swal.getPopup().querySelector("#username").value;
        const surname = Swal.getPopup().querySelector("#surname").value;
        const city = Swal.getPopup().querySelector("#city").value;
        const birthday = Swal.getPopup().querySelector("#birthday").value;
        const password = Swal.getPopup().querySelector("#password").value;
        const coutry = Swal.getPopup().querySelector("#coutry").value;
        const confirmPassword =
          Swal.getPopup().querySelector("#confirmPassword").value;
        if (!username || !surname || !city || !birthday || !coutry) {
          Swal.showValidationMessage(`Wypełnij puste pola`);
        }
        if ((password && !confirmPassword) || (!password && confirmPassword)) {
          Swal.showValidationMessage(`Musisz podać oba hasła`);
        }
        if (password) {
          if (password.length < 6) {
            Swal.showValidationMessage(`Hasło musi posiadać minimum 6 znaków`);
          }
        }
        if (password !== confirmPassword) {
          Swal.showValidationMessage(`Hasła muszą być takie same`);
        }
        return {
          username,
          surname,
          city,
          coutry,
          birthday,
          password,
          confirmPassword,
        };
      },
    }).then((result) => {
      if (result.value?.username) {
        const updateUser = async () => {
          try {
            if (result.value.password === "" || result.value.password === "") {
              await axios.put(
                `https://noclegjura.pl/backend/api/users/${CurrentUser._id}`,
                {
                  username: result.value?.username,
                  surname: result.value?.surname,
                  city: result.value?.city,
                  age: result.value?.birthday,
                  coutry: result.value?.coutry,
                  role: "user",
                }
              );
              const userUpdate = {
                _id: CurrentUser._id,
                username: result.value?.username,
                login: CurrentUser.login,
                surname: result.value?.surname,
                city: result.value?.city,
                remind: CurrentUser.remind,
                img: CurrentUser.img,
                coutry: result.value?.coutry,
                number: CurrentUser.number,
                email: CurrentUser.email,
                age: result.value?.birthday,
                sex: CurrentUser.sex,
                role: CurrentUser.role,
                parentName: CurrentUser.parentName,
                parentSurname: CurrentUser.parentSurname,
                parentNumber: CurrentUser.parentNumber,
                parentEmail: CurrentUser.parentEmail,
                total: CurrentUser.total,
                bieganie: CurrentUser.bieganie,
                chodzenie: CurrentUser.chodzenie,
                rolki: CurrentUser.rolki,
                plywanie: CurrentUser.plywanie,
                rower: CurrentUser.rower,
                wozek: CurrentUser.wozek,
                wioslo: CurrentUser.wioslo,
                kajak: CurrentUser.kajak,
                dzieckoBieg: CurrentUser.dzieckoBieg,
                dzieckoChod: CurrentUser.dzieckoChod,
                different: CurrentUser.different,
              };
              dispatch({ type: "LOGIN_SUCCESS", payload: userUpdate });
              CurrentUser.remind
                ? window.localStorage.setItem(
                    "user",
                    JSON.stringify(userUpdate)
                  )
                : window.sessionStorage.setItem(
                    "user",
                    JSON.stringify(userUpdate)
                  );
              setCurrentUser(userUpdate);
            } else {
              await axios.put(
                `https://noclegjura.pl/backend/api/users/password/${CurrentUser._id}`,
                {
                  password: result.value?.password,
                  role: "user",
                }
              );
            }
            Swal.fire({
              icon: "success",
              title: "Zapisano zmiany",
              confirmButtonText: `Ok`,
              confirmButtonColor: "#c01820",
              denyButtonColor: "gray",
            });
          } catch (err) {
            console.log(err);
          }
        };
        updateUser();
      }
    });
  };
  useEffect(() => {
    const total = activities.map((a) => Number(a.distance));
    let score = 0;
    for (let i = 0; i < total.length; i++) {
      score += total[i];
    }

    const bieganie = activities.filter((a) => a.sport === "Bieg");
    const scoreB = bieganie.map((a) => Number(a.distance));
    let scoreBieg = 0;
    for (let i = 0; i < scoreB.length; i++) {
      scoreBieg += scoreB[i];
    }

    const chodzenie = activities.filter((a) => a.sport === "Chód");
    const scoreCh = chodzenie.map((a) => Number(a.distance));
    let scoreChod = 0;
    for (let i = 0; i < scoreCh.length; i++) {
      scoreChod += scoreCh[i];
    }

    const plywanie = activities.filter((a) => a.sport === "Pływanie");
    const scoreply = plywanie.map((a) => Number(a.distance));
    let scorePl = 0;
    for (let i = 0; i < scoreply.length; i++) {
      scorePl += scoreply[i];
    }

    const wozek = activities.filter(
      (a) => a.sport === "Jazda na wózku inwalidzkim"
    );
    const scoreWozek = wozek.map((a) => Number(a.distance));
    let scoreWoz = 0;
    for (let i = 0; i < scoreWozek.length; i++) {
      scoreWoz += scoreWozek[i];
    }

    const kajak = activities.filter((a) => a.sport === "Kajakarstwo");
    const scoreKaj = kajak.map((a) => Number(a.distance));
    let scoreKa = 0;
    for (let i = 0; i < scoreKaj.length; i++) {
      scoreKa += scoreKaj[i];
    }
    const wioslo = activities.filter((a) => a.sport === "Wioślarstwo");
    const scoreWio = wioslo.map((a) => Number(a.distance));
    let scoreW = 0;
    for (let i = 0; i < scoreWio.length; i++) {
      scoreW += scoreWio[i];
    }
    const rower = activities.filter((a) => a.sport === "Jazda na rowerze");
    const scoreRowe = rower.map((a) => Number(a.distance));
    let scoreRo = 0;
    for (let i = 0; i < scoreRowe.length; i++) {
      scoreRo += scoreRowe[i];
    }
    const rolki = activities.filter((a) => a.sport === "Jazda na rolkach");
    const scoreRolk = rolki.map((a) => Number(a.distance));
    let scoreRol = 0;
    for (let i = 0; i < scoreRolk.length; i++) {
      scoreRol += scoreRolk[i];
    }
    const dzieckoBieganie = activities.filter(
      (a) => a.sport === "Bieg z wózkiem dziecięcym"
    );
    const scoreDziecB = dzieckoBieganie.map((a) => Number(a.distance));
    let scoreDziec = 0;
    for (let i = 0; i < scoreDziecB.length; i++) {
      scoreDziec += scoreDziecB[i];
    }
    const dzieckoChodzenie = activities.filter(
      (a) => a.sport === "Chód z wózkiem dziecięcym"
    );
    const scoreDzieckoCh = dzieckoChodzenie.map((a) => Number(a.distance));
    let scoreDziecko = 0;
    for (let i = 0; i < scoreDzieckoCh.length; i++) {
      scoreDziecko += scoreDzieckoCh[i];
    }
    const different = activities.filter((a) => a.sport === "Inne");
    const scoreDiffe = different.map((a) => Number(a.distance));
    let scoreDi = 0;
    for (let i = 0; i < scoreDiffe.length; i++) {
      scoreDi += scoreDiffe[i];
    }
    const updateUser = async () => {
      await axios.put(
        `https://noclegjura.pl/backend/api/users/${CurrentUser._id}`,
        {
          total: score,
          bieganie: scoreBieg,
          chodzenie: scoreChod,
          plywanie: scorePl,
          wozek: scoreWoz,
          kajak: scoreKa,
          wioslo: scoreW,
          rolki: scoreRol,
          rower: scoreRo,
          dzieckoBieg: scoreDziec,
          dzieckoChod: scoreDziecko,
          different: scoreDi,
          role: "user",
        }
      );
    };
    updateUser();
  }, [activities, CurrentUser._id]);

  const handleShowResults = () => {
    Swal.fire({
      title: "Twoje rezultaty",
      html: `
      <div style="padding: 10px; font-size: 25px; border-bottom: 1px solid black;">
      <span>Bieg: ${
        bieganieScore === 0
          ? `<span style="font-weight: bold;">Nie bierzesz udziału</span>`
          : `<span style="font-weight: bold;">${String(bieganieScore).substr(
              0,
              String(bieganieScore)?.indexOf(".") > 0
                ? String(bieganieScore)?.indexOf(".") + 3
                : bieganieScore.length
            )} km / ${
              Number(window.sessionStorage.getItem("bieganie")) + 1
            } miejsce</span>`
      }</span>
    </div>
      <div style="padding: 10px; font-size: 25px; border-bottom: 1px solid black;">
        <span>Chód: ${
          chodzenieScore === 0
            ? `<span style="font-weight: bold;">Nie bierzesz udziału</span>`
            : `<span style="font-weight: bold;">${String(chodzenieScore).substr(
                0,
                String(chodzenieScore)?.indexOf(".") > 0
                  ? String(chodzenieScore)?.indexOf(".") + 3
                  : chodzenieScore.length
              )} km / ${
                Number(window.sessionStorage.getItem("chodzenie")) + 1
              } miejsce</span>`
        }</span>
      </div>
      <div style="padding: 10px; font-size: 25px; border-bottom: 1px solid black;">
        <span>Jazda na rolkach: ${
          rolkiScore === 0
            ? `<span style="font-weight: bold;">Nie bierzesz udziału</span>`
            : `<span style="font-weight: bold;">${String(rolkiScore).substr(
                0,
                String(rolkiScore)?.indexOf(".") > 0
                  ? String(rolkiScore)?.indexOf(".") + 3
                  : rolkiScore.length
              )} km / ${
                Number(window.sessionStorage.getItem("rolki")) + 1
              } miejsce</span>`
        }</span>
      </div>
      <div style="padding: 10px; font-size: 25px; border-bottom: 1px solid black;">
        <span>Pływanie: ${
          plywanieScore === 0
            ? `<span style="font-weight: bold;">Nie bierzesz udziału</span>`
            : `<span style="font-weight: bold;">${String(plywanieScore).substr(
                0,
                String(plywanieScore)?.indexOf(".") > 0
                  ? String(plywanieScore)?.indexOf(".") + 3
                  : plywanieScore.length
              )} km / ${Number(
                window.sessionStorage.getItem("plywanie") + 1
              )} miejsce</span>`
        }</span>
      </div>
      <div style="padding: 10px; font-size: 25px; border-bottom: 1px solid black;">
      <span>Jazda na rowerze: ${
        rowerScore === 0
          ? `<span style="font-weight: bold;">Nie bierzesz udziału</span>`
          : `<span style="font-weight: bold;">${String(rowerScore).substr(
              0,
              String(rowerScore)?.indexOf(".") > 0
                ? String(rowerScore)?.indexOf(".") + 3
                : rowerScore.length
            )} km / ${
              Number(window.sessionStorage.getItem("rower")) + 1
            } miejsce</span>`
      }</span>
      </div>
      <div style="padding: 10px; font-size: 25px; border-bottom: 1px solid black;">
      <span>Wózek inwalidzki: ${
        wozekScore === 0
          ? `<span style="font-weight: bold;">Nie bierzesz udziału</span>`
          : `<span style="font-weight: bold;">${String(wozekScore).substr(
              0,
              String(wozekScore)?.indexOf(".") > 0
                ? String(wozekScore)?.indexOf(".") + 3
                : wozekScore.length
            )} km / ${
              Number(window.sessionStorage.getItem("wozek")) + 1
            } miejsce</span>`
      }</span>
      </div>
      <div style="padding: 10px; font-size: 25px; border-bottom: 1px solid black;">
      <span>Wioślarstwo: ${
        wiosloScore === 0
          ? `<span style="font-weight: bold;">Nie bierzesz udziału</span>`
          : `<span style="font-weight: bold;">${String(wiosloScore).substr(
              0,
              String(wiosloScore)?.indexOf(".") > 0
                ? String(wiosloScore)?.indexOf(".") + 3
                : wiosloScore.length
            )} km / ${
              Number(window.sessionStorage.getItem("wioslo")) + 1
            } miejsce</span>`
      }</span>
      </div>
      <div style="padding: 10px; font-size: 25px; border-bottom: 1px solid black;">
      <span>Kajakarstwo: ${
        kajakScore === 0
          ? `<span style="font-weight: bold;">Nie bierzesz udziału</span>`
          : `<span style="font-weight: bold;">${String(kajakScore).substr(
              0,
              String(kajakScore)?.indexOf(".") > 0
                ? String(kajakScore)?.indexOf(".") + 3
                : kajakScore.length
            )} km / ${
              Number(window.sessionStorage.getItem("kajak")) + 1
            } miejsce</span>`
      }</span>
      </div>
      <div style="padding: 10px; font-size: 25px; border-bottom: 1px solid black;">
      <span>Chód z wózkiem dziecięcym: ${
        dzieckoChodScore === 0
          ? `<span style="font-weight: bold;">Nie bierzesz udziału</span>`
          : `<span style="font-weight: bold;">${String(dzieckoChodScore).substr(
              0,
              String(dzieckoChodScore)?.indexOf(".") > 0
                ? String(dzieckoChodScore)?.indexOf(".") + 3
                : dzieckoChodScore.length
            )} km / ${
              Number(window.sessionStorage.getItem("dzieckoChod")) + 1
            } miejsce</span>`
      }</span>
      </div>
      <div style="padding: 10px; font-size: 25px; border-bottom: 1px solid black;">
      <span>Bieg z wózkiem dziecięcym: ${
        dzieckoBiegScore === 0
          ? `<span style="font-weight: bold;">Nie bierzesz udziału</span>`
          : `<span style="font-weight: bold;">${String(dzieckoBiegScore).substr(
              0,
              String(dzieckoBiegScore)?.indexOf(".") > 0
                ? String(dzieckoBiegScore)?.indexOf(".") + 3
                : dzieckoBiegScore.length
            )} km / ${
              Number(window.sessionStorage.getItem("dzieckoBieg")) + 1
            } miejsce</span>`
      }</span>
      </div>
      <div style="padding: 10px; font-size: 25px; border-bottom: 1px solid black;">
      <span>Inne: ${
        differentScore === 0
          ? `<span style="font-weight: bold;">Nie bierzesz udziału</span>`
          : `<span style="font-weight: bold;">${String(differentScore).substr(
              0,
              String(differentScore)?.indexOf(".") > 0
                ? String(differentScore)?.indexOf(".") + 3
                : differentScore.length
            )} km / ${
              Number(window.sessionStorage.getItem("different")) + 1
            } miejsce</span>`
      }</span>
      </div>
      `,
      showConfirmButton: false,
      showCloseButton: true,
      confirmButtonColor: "#F03C37",
      focusConfirm: false,
    });
  };

  const handleChangePhoto = (e) => {
    setFile(e.target.files[0]);
  };

  const handleAddActivity = () => {
    Swal.fire({
      title: "Wyzwanie zostało zakończone",
      footer: "Dziękujemy za wzięcie udziału!",
      confirmButtonText: `Ok`,
      confirmButtonColor: "#c01820",
      denyButtonColor: "gray",
    });
    // Swal.fire({
    //   title: "Dodaj aktywność",
    //   html: `<select id="sport" class="swal2-input">
    //   <option>Chód</option>
    //   <option>Bieg</option>
    //   <option>Jazda na rolkach</option>
    //   <option>Pływanie</option>
    //   <option>Jazda na rowerze</option>
    //   <option>Jazda na wózku inwalidzkim</option>
    //   <option>Wioślarstwo</option>
    //   <option>Kajakarstwo</option>
    //   <option>Bieg z wózkiem dziecięcym</option>
    //   <option>Chód z wózkiem dziecięcym</option>
    //   <option>Inne</option>
    //   </select>
    //   <input type="text" id="distance" class="swal2-input" placeholder="pokonany dystans..."> <span>km</span>
    //   <p style="position:absolute; left: 50%; transform: translateX(-50%);">Potwierdzenie aktywności</p>
    //   <input type="file" id="file" style="width:80%; margin-top:50px;" class="swal2-input" placeholder="pokonany dystans...">
    //   `,
    //   confirmButtonText: "Dodaj",
    //   showCancelButton: true,
    //   cancelButtonText: "Anuluj",
    //   showCloseButton: true,
    //   confirmButtonColor: "#c01820",
    //   focusConfirm: false,
    //   preConfirm: () => {
    //     const sport = Swal.getPopup().querySelector("#sport").value;
    //     const distance = Swal.getPopup().querySelector("#distance").value;
    //     const file = Swal.getPopup().querySelector("#file");
    //     if (distance.includes(",")) {
    //       Swal.showValidationMessage(`Zamiast przecinka użyj "."`);
    //     }
    //     if (isNaN(distance)) {
    //       Swal.showValidationMessage(
    //         `Nieprawidłowa odległość, użyj "." zamiast przecinka.`
    //       );
    //     }
    //     if (!distance || !file || !sport) {
    //       Swal.showValidationMessage(`Proszę wypełnić wszystkie pola`);
    //     }
    //     if (file.files[0]?.size > 1000000) {
    //       Swal.showValidationMessage(
    //         `Zdjęcie jest za duże, zmniejsz rozdzielczość!`
    //       );
    //     }
    //     return { sport, distance, file };
    //   },
    // }).then((result) => {
    //   if (result.value?.sport) {
    //     const uploadImage = async () => {
    //       const data = new FormData();
    //       const Datanow = Date.now();
    //       const fileName = `${Datanow}${result.value.file.files[0].name}`;
    //       data.append("name", fileName);
    //       data.append("file", result.value.file.files[0]);
    //       try {
    //         await axios.post("https://noclegjura.pl/backend/api/upload", data);
    //         console.log(data);
    //       } catch (err) {
    //         console.log(err);
    //       }
    //       const newActivity = async () => {
    //         try {
    //           await axios.post("https://noclegjura.pl/backend/api/activity", {
    //             login: CurrentUser.login,
    //             username: CurrentUser.username,
    //             surname: CurrentUser.surname,
    //             city: CurrentUser.city,
    //             coutry: CurrentUser.coutry,
    //             age: CurrentUser.age,
    //             sport: result.value.sport,
    //             distance: result.value.distance,
    //             img: `https://noclegjura.pl/backend/${Datanow}${result.value.file.files[0].name}`,
    //             role: "user",
    //           });
    //           Swal.fire({
    //             icon: "success",
    //             title: "Dodano aktywność",
    //             confirmButtonText: `Ok`,
    //             confirmButtonColor: "#c01820",
    //             footer: "Aktualizacja rankingu potrwa do 1-2minut",
    //             denyButtonColor: "gray",
    //           }).then(() => {
    //             history.push("/history");
    //           });
    //         } catch (err) {
    //           console.log(err);
    //         }
    //       };
    //       newActivity();
    //     };
    //     uploadImage();
    //   }
    // });
  };

  useEffect(() => {
    if (file) {
      const uploadImage = async () => {
        const userUpdate = {
          _id: CurrentUser._id,
          username: CurrentUser.username,
          login: CurrentUser.login,
          surname: CurrentUser.surname,
          city: CurrentUser.city,
          coutry: CurrentUser.coutry,
          number: CurrentUser.number,
          remind: CurrentUser.remind,
          email: CurrentUser.email,
          age: CurrentUser.age,
          sex: CurrentUser.sex,
          role: "user",
          parentName: CurrentUser.parentName,
          parentSurname: CurrentUser.parentSurname,
          parentNumber: CurrentUser.parentNumber,
          parentEmail: CurrentUser.parentEmail,
          total: CurrentUser.total,
          bieganie: CurrentUser.bieganie,
          chodzenie: CurrentUser.chodzenie,
          rolki: CurrentUser.rolki,
          plywanie: CurrentUser.plywanie,
          rower: CurrentUser.rower,
          wozek: CurrentUser.wozek,
          wioslo: CurrentUser.wioslo,
          kajak: CurrentUser.kajak,
          dzieckoBieg: CurrentUser.dzieckoBieg,
          dzieckoChod: CurrentUser.dzieckoChod,
          different: CurrentUser.different,
        };
        const data = new FormData();
        const Datanow = Date.now();
        const fileName = `${Datanow}${file.name}`;
        data.append("name", fileName);
        data.append("file", file);
        userUpdate.img = `https://noclegjura.pl/backend/${Datanow}${file.name}`;
        try {
          await axios.post("https://noclegjura.pl/backend/api/upload", data);
          console.log(data);
        } catch (err) {
          console.log(err);
        }
        try {
          await axios.put(
            `https://noclegjura.pl/backend/api/users/${CurrentUser._id}`,
            {
              img: `https://noclegjura.pl/backend/${Datanow}${file.name}`,
              role: "user",
            }
          );
          dispatch({ type: "LOGIN_SUCCESS", payload: userUpdate });
          CurrentUser.remind
            ? window.localStorage.setItem("user", JSON.stringify(userUpdate))
            : window.sessionStorage.setItem("user", JSON.stringify(userUpdate));
          window.location.reload();
        } catch (err) {
          console.log(err);
        }
      };
      uploadImage();
    }
  }, [file, CurrentUser, dispatch]);
  const handleLogout = () => {
    dispatch({ type: "LOGOUT" });
    window.localStorage.clear();
    window.sessionStorage.clear();
    window.location.reload();
  };

  const Aday = new Date().getDate();
  return (
    <>
      <Topbar profile={true} />
      <div className="profile">
        <div className="wrapper">
          <div className="desc">
            <div className="img">
              <label htmlFor="file-input">
                <div className="changePhoto" onChange={handleChangePhoto}>
                  <CameraAlt className="icon" />
                </div>
              </label>
              <input
                style={{ display: "none" }}
                type="file"
                id="file-input"
                accept=".png,.jpeg,.jpg"
                onChange={handleChangePhoto}
              />
              <img
                src={
                  CurrentUser.img === ""
                    ? `${process.env.PUBLIC_URL}/assets/63dni_${user.sex}.jpg`
                    : CurrentUser.img
                }
                alt="avatar"
              />
            </div>
            <span className="name">
              {user.username} {user.surname}
            </span>
            <span className="city">
              {user.city}, {user.coutry}
            </span>
            <span className="age">
              {user.sex === "man" ? "Mężczyzna" : "Kobieta"}, {age} lat
            </span>
            <span
              className="edit"
              onClick={handleLogout}
              style={{ marginTop: "10px" }}
            >
              WYLOGUJ SIĘ
            </span>
            <span className="edit" onClick={handleEditProfile}>
              EDYTUJ PROFIL / ZMIEŃ HASŁO
            </span>
          </div>
          <div className="results">
            <div className="place">
              <span className="placeDesc">
                Ranking total:{" "}
                <div className="placeCount">
                  {users?.findIndex((u) => u.login === user.login) + 1}/
                  {users.length}
                </div>
              </span>
            </div>
            <div className="distance">
              <span className="descDistance">
                Pokonany dystans:{" "}
                <span className="distanceTotal">
                  {String(totalScore).substr(
                    0,
                    String(totalScore)?.indexOf(".") > 0
                      ? String(totalScore)?.indexOf(".") + 3
                      : totalScore.length
                  )}{" "}
                  km
                </span>
              </span>
              <span className="allResult" onClick={handleShowResults}>
                ZOBACZ WSZYSTKIE WYNIKI
              </span>
            </div>
            <div className="buttons">
              <Button
                variant="contained"
                color="secondary"
                className="button"
                style={{ backgroundColor: "#c01820" }}
                onClick={handleAddActivity}
              >
                Dodaj aktywność
              </Button>
              <Link to="/history" style={{ textDecoration: "none" }}>
                <Button
                  variant="outlined"
                  color="secondary"
                  style={{
                    borderColor: "#c01820",
                    color: "#c01820",
                    marginLeft: "10px",
                  }}
                >
                  Historia aktywności
                </Button>
              </Link>
            </div>
            <div className="history">
              {
                <img
                  src={`${process.env.PUBLIC_URL}/assets/logo-min.svg`}
                  alt=""
                  className="icon"
                />
              }
              <div className="historyDesc">
                <span>
                  Kalendarz Powstania Warszawskiego:{" "}
                  <span>{Aday} października</span>
                </span>
                <a
                  href="https://www.63dni.pl/kalendarz-powstania-2.html"
                  style={{ textDecoration: "none" }}
                >
                  <p>Co wydarzyło się tego dnia...</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Ranking totalS={totalScore} initial={users} profile={true} />
    </>
  );
};

export default Profile;
