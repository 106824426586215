import React, { useEffect, useRef } from "react";
import "./topbar.css";
import { Link } from "react-router-dom";
import { Menu, Close } from "@material-ui/icons";
const Topbar = ({ register, profile, history, login, ranking }) => {
  const TextStart = useRef();
  const start = useRef();
  const TextProfile = useRef();
  const profilea = useRef();
  const TextRegister = useRef();
  const registera = useRef();
  const TextStarth = useRef();
  const starth = useRef();
  const TextProfileh = useRef();
  const profileah = useRef();
  const TextRegisterh = useRef();
  const registerah = useRef();
  const TextRankingh = useRef();
  const rankingh = useRef();
  const TextRanking = useRef();
  const rankinga = useRef();


  const hamburger = useRef();
  const handleHamburger = (value) => {
    if (value === "on") {
      hamburger.current.style = `display:block`;
    } else {
      hamburger.current.style = `display:none`;
    }
  };

  useEffect(() => {
    if (login) {
      TextStart.current.style = "color: black";
      start.current.style = "width: 100%";
    } else if (profile || history) {
      TextProfile.current.style = "color: black";
      profilea.current.style = "width: 100%";
    } else if (register) {
      TextRegister.current.style = "color: black";
      registera.current.style = "width: 100%";
    } else if (ranking) {
      TextRanking.current.style = "color: black";
      rankinga.current.style = "width: 100%";
    }
    if (TextStarth.current) {
      if (login) {
        TextStarth.current.style = "color: #c01820";
      } else if (profile || history) {
        TextProfileh.current.style = "color: #c01820";
      } else if (register) {
        TextRegisterh.current.style = "color: #c01820";
      } else if (ranking) {
        TextRankingh.current.style = "color: #c01820";
      }
    }
  });

  return (
    <div className="topbar">
      <div className="logo">
        <img src={`${process.env.PUBLIC_URL}/assets/logo.png`} alt="" />
      </div>
      <div className="menu">
        <div className="hamburger">
          <Menu className="icon" onClick={() => handleHamburger("on")} />
        </div>
        <div className="hamburgerMenu" ref={hamburger}>
          <div className="goBack">
            <Close className="icon" onClick={() => handleHamburger("off")} />
          </div>
          <div className="ulList">
            <ul>
              <a href="asd.pl" className="linka">
                <li ref={TextStarth}>
                  START<div className="border" ref={starth}></div>
                </li>
              </a>
              <Link to="/register" className="linka">
                <li ref={TextRegisterh}>
                  REJESTRACJA<div className="border" ref={registerah}></div>
                </li>
              </Link>
              <Link to="/profile" className="linka">
                <li ref={TextProfileh}>
                  PROFIL<div className="border" ref={profileah}></div>
                </li>
              </Link>
              <Link to="/rank" className="linka">
                <li ref={TextRankingh}>
                  RANKING<div className="border" ref={rankingh}></div>
                </li>
              </Link>
              <a href="https://www.63dni.pl/o-wyzwaniu.html" className="linka">
                <li>
                  O WYZWANIU<div className="border"></div>
                </li>
              </a>
              <a
                href="https://www.63dni.pl/medal-koszulki.html"
                className="linka"
              >
                <li>
                  MEDAL/KOSZULKA<div className="border"></div>
                </li>
              </a>
              <a
                href="https://www.63dni.pl/wsparcie-muzeum.html"
                className="linka"
              >
                <li>
                  WSPARCIE MUZEUM<div className="border"></div>
                </li>
              </a>
              <a href="https://www.63dni.pl/kalendarz-powstania-2.html" className="linka">
                <li>
                  KALENDARZ POWSTANIA<div className="border"></div>
                </li>
              </a>
              <a href="https://www.63dni.pl/powstancy.html" className="linka">
                <li>
                  POWSTAŃCY<div className="border"></div>
                </li>
              </a>
              <a href="https://www.63dni.pl/kontakt.html" className="linka">
                <li>
                  KONTAKT<div className="border"></div>
                </li>
              </a>
            </ul>
          </div>
        </div>
        <ul className="ul">
          <a href="https://www.63dni.pl/" className="link">
            <li ref={TextStart}>
              START<div className="border" ref={start}></div>
            </li>
          </a>
          <Link to="/register" className="link">
            <li ref={TextRegister}>
              REJESTRACJA<div className="border" ref={registera}></div>
            </li>
          </Link>
          <Link to="/profile" className="link">
            <li ref={TextProfile}>
              PROFIL<div className="border" ref={profilea}></div>
            </li>
          </Link>
          <Link to="/rank" className="link">
            <li ref={TextRanking}>
              RANKING<div className="border" ref={rankinga}></div>
            </li>
          </Link>
          <a href="https://www.63dni.pl/o-wyzwaniu.html" className="link">
            <li>
              O WYZWANIU<div className="border"></div>
            </li>
          </a>
          <a href="https://www.63dni.pl/medal-koszulki.html" className="link">
            <li>
              MEDAL/KOSZULKA<div className="border"></div>
            </li>
          </a>
          <a href="https://www.63dni.pl/wsparcie-muzeum.html" className="link">
            <li>
              WSPARCIE MUZEUM<div className="border"></div>
            </li>
          </a>
          <a href="https://www.63dni.pl/kalendarz-powstania-2.html" className="link">
            <li>
              KALENDARZ POWSTANIA<div className="border"></div>
            </li>
          </a>
          <a href="https://www.63dni.pl/powstancy.html" className="link">
            <li>
              POWSTAŃCY<div className="border"></div>
            </li>
          </a>
          <a href="https://www.63dni.pl/kontakt.html" className="link">
            <li>
              KONTAKT<div className="border"></div>
            </li>
          </a>
        </ul>
      </div>
    </div>
  );
};

export default Topbar;
