import React, { useEffect, useState, useRef } from "react";
import { TextField } from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";
import Topbar from "../../components/Topbar/Topbar";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import RankingUser from "../../components/rankingUser/RankingUser";
import axios from "axios";
const AdminUsers = () => {
  const [username, setUsername] = useState("");
  const [users, setUsers] = useState([]);
  const sortedUsers = useRef([]);

  const handleSortUsers = (e) => {
    setUsername(e.target.value);
    const sorted = sortedUsers.current?.filter((u) =>
      u.surname.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setUsers(sorted);
  };

  useEffect(() => {
    const getUsers = async () => {
      try {
        const res = await axios.get(
          "https://noclegjura.pl/backend/api/users/all"
        );
        setUsers(res.data);
        sortedUsers.current = res.data;
      } catch (err) {
        console.log(err);
      }
    };
    getUsers();
  }, []);

  const usersList = users.map((u, index) => (
    <RankingUser user={u} key={u._id} index={index} admin={true} />
  ));

  const handleSortCity = () => {
    Swal.fire({
      title: "Sortuj ranking",
      html: `<input type="text" id="city" class="swal2-input" placeholder="miasto...">`,
      confirmButtonText: "Sortuj",
      confirmButtonColor: "#c01820",
      cancelButtonText: "Anuluj",
      showCancelButton: true,
      focusConfirm: false,
      preConfirm: () => {
        const city = Swal.getPopup().querySelector("#city").value;
        if (!city) {
          Swal.showValidationMessage(`Wypełnij powyższe pole`);
        }
        return { city: city };
      },
    }).then((result) => {
      if (result.value?.city) {
        const sortedType = sortedUsers.current.filter(
          (u) => u.city.toLowerCase() === result.value.city.toLowerCase()
        );
        setUsers(sortedType);
      }
    });
  };

  return (
    <>
      <Topbar />
      <div className="historyActivity">
        <div className="desc">
          <span>PANEL ADMINA</span>
        </div>
        <div className="headline" style={{ marginTop: "50px" }}>
          <div className="data">
            <span>#</span>
          </div>
          <div className="data">
            <span>Imię i nazwisko</span>
          </div>
          <div className="data">
            <span>Telefon</span>
          </div>
          <div className="data">
            <span>Miasto</span>
            <ArrowDropDown className="dataIcon" onClick={handleSortCity} />
          </div>
          <div className="data">
            <span>Kraj</span>
          </div>
          <div className="data">
            <span>Wiek</span>
          </div>
          <TextField
            id="standard-basic"
            label="Nazwisko"
            className="select"
            value={username}
            onChange={handleSortUsers}
          />
          <Link to="/admin" style={{ textDecoration: "none" }}>
            <span className="iconPerson">Aktywności</span>
          </Link>
        </div>
        <div className="historyUser">{usersList}</div>
      </div>
    </>
  );
};

export default AdminUsers;
