import React, { useContext, useEffect, useState, useRef } from "react";
import "./history.css";
import HistoryItem from "./HistoryItem/HistoryItem";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";
import Topbar from "../../components/Topbar/Topbar";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import axios from "axios";
import { Context } from "../../context/Context";
const History = ({ admin }) => {
  const [activity, setActivity] = useState([]);
  const sortedActivity = useRef();
  const sortedActiv = useRef();
  const [allActivity, setAllActivity] = useState([]);
  const [sortClicked, setSortClicked] = useState(false);
  const [select, setSelect] = useState("Total");
  const { user } = useContext(Context);
  const [username, setUsername] = useState();

  const handleChangeSport = (e) => {
    setSelect(e.target.value);
    const sorted = sortedActivity.current?.filter(
      (a) => a.sport === e.target.value
    );
    setAllActivity(sorted);
  };
  const handleSortUsers = (e) => {
    setUsername(e.target.value);
    if (select === "Total") {
      const sorted = sortedActivity.current.filter((u) =>
        u.surname.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setAllActivity(sorted);
    } else {
      const sortedAct = sortedActivity.current.filter(
        (u) => u.sport === select
      );
      const sorted = sortedAct.filter((u) =>
        u.surname.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setAllActivity(sorted);
    }
  };

  useEffect(() => {
    const getUserActivity = async () => {
      try {
        const res = await axios.post(
          "https://noclegjura.pl/backend/api/activity/user",
          {
            login: user.login,
            role: "user",
          }
        );
        setActivity(res.data);
        sortedActiv.current = res.data;
      } catch (err) {
        console.log(err);
      }
    };
    getUserActivity();
  }, [user.login]);

  useEffect(() => {
    const getAllActivity = async () => {
      try {
        const res = await axios.post(
          "https://noclegjura.pl/backend/api/activity/all",
          {
            role: "admin",
          }
        );
        setAllActivity(res.data);
        sortedActivity.current = res.data;
      } catch (err) {
        console.log(err);
      }
    };
    getAllActivity();
  }, []);

  const activityList = activity?.map((a) => (
    <HistoryItem activity={a} key={a._id} />
  ));
  const AllActivityList = allActivity?.map((a) => (
    <HistoryItem activity={a} key={a._id} admin={true} />
  ));

  const handleSortData = () => {
    if (admin) {
      console.log(sortClicked);
      if (!sortClicked) {
        const sorted = sortedActivity.current.sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
        setSortClicked((prev) => !prev);
        setAllActivity(sorted);
      } else {
        window.location.reload();
      }
    }
  };
  const handleSortCity = () => {
    Swal.fire({
      title: "Sortuj ranking",
      html: `<input type="text" id="city" class="swal2-input" placeholder="miasto...">`,
      confirmButtonText: "Sortuj",
      confirmButtonColor: "#c01820",
      cancelButtonText: "Anuluj",
      showCancelButton: true,
      focusConfirm: false,
      preConfirm: () => {
        const city = Swal.getPopup().querySelector("#city").value;
        if (!city) {
          Swal.showValidationMessage(`Wypełnij powyższe pole`);
        }
        return { city: city };
      },
    }).then((result) => {
      if (result.value?.city) {
        const sortedType = allActivity.filter(
          (u) => u.city.toLowerCase() === result.value.city.toLowerCase()
        );
        setAllActivity(sortedType);
      }
    });
  };

  let body;
  if (admin) {
    body = (
      <div className="historyActivity">
        <div className="desc">
          <span>PANEL ADMINA</span>
        </div>
        <div className="headline" style={{ marginTop: "50px" }}>
          <div className="data">
            <span>#</span>
          </div>
          <div className="data">
            <span>Dyscyplina</span>
          </div>
          <div className="data">
            <span>Dystans</span>
          </div>
          <div className="data">
            <span>Miasto</span>
            <ArrowDropDown className="dataIcon" onClick={handleSortCity} />
          </div>
          <div className="data">
            <span>Zdjęcie</span>
          </div>
          <div className="data">
            <span>Data</span>
            <ArrowDropDown className="dataIcon" onClick={handleSortData} />
          </div>
          <div className="data">
            <span></span>
          </div>
          <FormControl className="select">
            <InputLabel id="demo-controlled-open-select-label">
              Dyscyplina
            </InputLabel>
            <Select
              labelId="demo-controlled-open-select-label"
              id="demo-controlled-open-select"
              value={select}
              onChange={handleChangeSport}
            >
              <MenuItem value="Total">Total</MenuItem>
              <MenuItem value="Bieg">Bieganie</MenuItem>
              <MenuItem value="Chód">Chodzenie</MenuItem>
              <MenuItem value="Jazda na rolkach">Jazda na rolkach</MenuItem>
              <MenuItem value="Pływanie">Pływanie</MenuItem>
              <MenuItem value="Jazda na rowerze">Jazda na rowerze</MenuItem>
              <MenuItem value="Jazda na wózku inwalidzkim">
                Jazda na wózku inwalidzkim
              </MenuItem>
              <MenuItem value="Wioślarstwo">Wioślarstwo</MenuItem>
              <MenuItem value="Kajakarstwo">Kajakarstwo</MenuItem>
              <MenuItem value="Bieg z wózkiem dziecięcym">
                Bieg z wózkiem dziecięcym
              </MenuItem>
              <MenuItem value="Chód z wózkiem dziecięcym">
                Chód z wózkiem dziecięcym
              </MenuItem>
              <MenuItem value="Inne">Inne</MenuItem>
            </Select>
          </FormControl>
          <Link to="/admin/users" style={{ textDecoration: "none" }}>
            <span className="iconPerson">Użytkownicy</span>
          </Link>
          <TextField
            id="standard-basic"
            label="Nazwisko"
            className="selectActivity"
            value={username}
            onChange={handleSortUsers}
          />
        </div>
        <div className="historyUser">{AllActivityList}</div>
      </div>
    );
  } else {
    body = (
      <>
        <Topbar history={true} />
        <div className="historyActivity">
          <div className="desc">
            <span>MOJE WYZWANIE</span>
          </div>
          <div className="headline">
            <div className="data">
              <span>#</span>
            </div>
            <div className="data">
              <span>Dyscyplina</span>
            </div>
            <div className="data">
              <span>Dystans</span>
            </div>
            <div className="data">
              <span>Miasto</span>
              <ArrowDropDown className="dataIcon" />
            </div>
            <div className="data">
              <span>Zdjęcie</span>
            </div>
            <div className="data">
              <span>Data</span>
              <ArrowDropDown className="dataIcon" />
            </div>
            <div className="data">
              <span></span>
            </div>
          </div>
          <div className="historyUser">{activityList}</div>
        </div>
      </>
    );
  }
  return body;
};

export default History;
