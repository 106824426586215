import React from "react";
import Topbar from "../../components/Topbar/Topbar";
import History from "../History/History";
const Admin = () => {
  return (
    <>
      <Topbar />
      <History admin={true} />
    </>
  );
};

export default Admin;
