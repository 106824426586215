import React, { useRef, useState } from "react";
import "./register.css";
import emailjs from "emailjs-com";
import {
  TextField,
  Checkbox,
  FormControlLabel,
  FormControl,
  Radio,
  RadioGroup,
  Button,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import axios from "axios";
import Swal from "sweetalert2";
// import ReCAPTCHA from "react-google-recaptcha";
import { useHistory } from "react-router";
import Topbar from "../../components/Topbar/Topbar";
const Register = () => {
  const history = useHistory();
  const username = useRef();
  const surname = useRef();
  const [coutry, setCoutry] = useState("Polska");
  const city = useRef();
  const number = useRef();
  const email = useRef();
  const login = useRef();
  const age = useRef();
  const password = useRef();
  const confirmPassword = useRef();
  const validationPassword = useRef();
  const validationPasswordLength = useRef();
  const [gender, setGender] = useState("man");
  const [parentName, setParentName] = useState();
  const [parentSurname, setParentSurname] = useState();
  const [parentNumber, setParentNumber] = useState();
  const [parentEmail, setParentEmail] = useState();
  const [usersEmail, setUsersEmail] = useState([]);
  const [isParentAccept, setIsParentAccept] = useState(false);
  const changeValid = () => {
    password.current.style = "border-bottom: none";
    confirmPassword.current.style = "border-bottom: none";
    validationPassword.current.style = "display: none";
    validationPasswordLength.current.style = "display: none";
  };
  // const onChangeCapatcha = () => {
  //   alert("asd");
  // };
  const today = new Date().toISOString().substr(0, 4);
  const handleSubmitForm = async (e) => {
    e.preventDefault();
    const dataBirth = age.current.value.substr(0, 4);
    if (today - dataBirth < 2) {
      Swal.fire({
        icon: "error",
        title: "Ustaw prawidłową date",
        confirmButtonText: `Ok`,
        confirmButtonColor: "#c01820",
        denyButtonColor: "gray",
      });
    } else {
      if (today - dataBirth < 18) {
        if (!isParentAccept) {
          Swal.fire({
            title: "Osoba niepełnoletnia",
            html: `<input type="text" id="username" class="swal2-input" placeholder="Imię rodzica">
        <input type="text" id="surname" class="swal2-input" placeholder="Nazwisko rodzica">
        <input type="text" id="number" class="swal2-input" placeholder="Nr. tel rodzica">
        <input type="email" id="email" class="swal2-input" placeholder="Email kontaktowy rodzica">
        <div style="width: 80%; margin-left: 50px; display: flex; align-items: center; margin-top: 40px">
        <input type="checkbox" id="check"> <span style="margin-right: 20px">Prawny opiekun wyraża zgodę na mój udział</span>
        </div>
        `,
            confirmButtonText: "Zatwierdź",
            confirmButtonColor: "#c01820",
            focusConfirm: false,
            preConfirm: () => {
              const username = Swal.getPopup().querySelector("#username").value;
              const surname = Swal.getPopup().querySelector("#surname").value;
              const number = Swal.getPopup().querySelector("#number").value;
              const email = Swal.getPopup().querySelector("#email").value;
              const check = Swal.getPopup().querySelector("#check").checked;
              if (!username || !surname || !number || !email) {
                Swal.showValidationMessage(`Wprowadź wszystkie dane`);
              }
              if (!check) {
                Swal.showValidationMessage(`Zgoda rodzica jest wymagana`);
              }
              return { username, surname, number, email };
            },
          }).then((result) => {
            if (result.value?.username) {
              setParentName(result.value.username);
              setParentSurname(result.value.surname);
              setParentNumber(result.value.number);
              setParentEmail(result.value.email);
              setIsParentAccept(true);
              Swal.fire({
                icon: "success",
                title: "Zapisano",
                confirmButtonText: `Ok`,
                confirmButtonColor: "#c01820",
                denyButtonColor: "gray",
              });
            }
          });
        }
      }
      if (password.current.value.length < 6) {
        password.current.style = "border-bottom: 2px solid red";
        validationPasswordLength.current.style = "display: block";
      } else if (password.current.value !== confirmPassword.current.value) {
        password.current.style = "border-bottom: 2px solid red";
        confirmPassword.current.style = "border-bottom: 2px solid red";
        validationPassword.current.style = "display: block";
      } else if (usersEmail.includes(email.current.value)) {
        Swal.fire({
          icon: "error",
          title: "Adres e-mail zajęty",
          confirmButtonText: `Cofnij`,
          confirmButtonColor: "#c01820",
          denyButtonColor: "gray",
        });
      } else {
        if (isParentAccept || today - dataBirth > 18) {
          try {
            const newUser = {
              username: username.current.value.trim(),
              login: login.current.value.trim(),
              surname: surname.current.value.trim(),
              city: city.current.value.trim(),
              coutry: coutry.trim(),
              number: number.current.value.trim(),
              email: email.current.value.trim(),
              age: age.current.value,
              password: password.current.value,
              sex: gender,
              role: "user",
              parentName: parentName ? parentName.trim() : "",
              parentSurname: parentSurname ? parentSurname.trim() : "",
              parentNumber: parentNumber ? parentNumber.trim() : "",
              parentEmail: parentEmail ? parentEmail.trim() : "",
            };
            console.log(newUser);
            await axios.post(
              "https://noclegjura.pl/backend/api/users/register",
              newUser
            );
            Swal.fire({
              icon: "success",
              title: "Zarejestrowano pomyślnie",
              confirmButtonText: `Ok`,
              confirmButtonColor: "#c01820",
              denyButtonColor: "gray",
            })
              .then(() => {
                if (today - dataBirth < 18) {
                  const sendEmail = async () => {
                    console.log(username.current.value, email.current.value);
                    await emailjs.send(
                      "service_6om6q9s",
                      "template_h2orq06",
                      {
                        name: username.current.value.trim(),
                        surname: surname.current.value.trim(),
                        email: parentEmail.trim(),
                      },
                      "user_MiVuI8DrOyolpGs9t82GM"
                    );
                  };
                  sendEmail();
                }
                const sendEmail = async () => {
                  console.log(username.current.value, email.current.value);
                  await emailjs.send(
                    "service_6om6q9s",
                    "template_shpgucw",
                    {
                      username: username.current.value.trim(),
                      surname: surname.current.value.trim(),
                      login: login.current.value.trim(),
                      password: password.current.value,
                      email: email.current.value.trim(),
                    },
                    "user_MiVuI8DrOyolpGs9t82GM"
                  );
                };
                sendEmail();
              })
              .then(() => {
                history.push("/app/login");
              });
          } catch (err) {
            Swal.fire({
              icon: "error",
              title: "Podany login jest zajęty",
              confirmButtonText: `Ok`,
              confirmButtonColor: "#c01820",
              denyButtonColor: "gray",
            });
          }
        }
      }
    }
  };
  const getUsers = async () => {
    try {
      const res = await axios.get(
        "https://noclegjura.pl/backend/api/users/all"
      );
      const data = res.data.map((user) => user.email);
      setUsersEmail(data);
    } catch (err) {
      console.log(err);
    }
  };
  getUsers();
  const handleChangeGender = (e) => {
    setGender(e.target.value);
  };
  const handleChangeCountry = (e) => {
    setCoutry(e.target.value);
  };
  return (
    <>
      <Topbar register={true} />
      <div className="register">
        <div className="wrapper">
          <div className="right">
            <div className="headline">
              <div className="head"></div>
              <span>REJESTRACJA</span>
            </div>
            <form onSubmit={handleSubmitForm}>
              <div className="row">
                <TextField
                  id="username"
                  label="Imię"
                  variant="outlined"
                  className="input"
                  inputRef={username}
                  color="secondary"
                  required
                  minLength="3"
                  style={{ marginRight: "20px" }}
                />
                <TextField
                  id="surname"
                  label="Nazwisko"
                  inputRef={surname}
                  variant="outlined"
                  color="secondary"
                  required
                  minLength="3"
                  className="input"
                />
              </div>
              <div className="row">
                <TextField
                  id="city"
                  label="Miasto"
                  inputRef={city}
                  className="input"
                  variant="outlined"
                  required
                  color="secondary"
                  style={{ marginRight: "20px" }}
                />
                <FormControl variant="filled" className="input">
                  <InputLabel id="demo-simple-select-filled-label">
                    Kraj
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    onChange={handleChangeCountry}
                    value={coutry}
                    style={{ maxHeight: "200px" }}
                  >
                    <MenuItem value={"Polska"}>Polska</MenuItem>
                    <MenuItem value={"Albania"}>Albania</MenuItem>
                    <MenuItem value={"Andora"}>Andora</MenuItem>
                    <MenuItem value={"Austria"}>Austria</MenuItem>
                    <MenuItem value={"Belgia"}>Belgia</MenuItem>
                    <MenuItem value={"Białoruś"}>Białoruś</MenuItem>
                    <MenuItem value={"Bośnia i Hercegowina"}>
                      Bośnia i Hercegowina
                    </MenuItem>
                    <MenuItem value={"Chorwacja"}>Chorwacja</MenuItem>
                    <MenuItem value={"Czechy"}>Czechy</MenuItem>
                    <MenuItem value={"Dania"}>Dania</MenuItem>
                    <MenuItem value={"Estonia"}>Estonia</MenuItem>
                    <MenuItem value={"Finlandia"}>Finlandia</MenuItem>
                    <MenuItem value={"Francja"}>Francja</MenuItem>
                    <MenuItem value={"Grecja"}>Grecja</MenuItem>
                    <MenuItem value={"Hiszpania"}>Hiszpania</MenuItem>
                    <MenuItem value={"Holandia"}>Holandia</MenuItem>
                    <MenuItem value={"Irlandia"}>Irlandia</MenuItem>
                    <MenuItem value={"Islandia"}>Islandia</MenuItem>
                    <MenuItem value={"Kazachstan"}>Kazachstan</MenuItem>
                    <MenuItem value={"Litwa"}>Litwa</MenuItem>
                    <MenuItem value={"Luksemburg"}>Luksemburg</MenuItem>
                    <MenuItem value={"Łotwa"}>Łotwa</MenuItem>
                    <MenuItem value={"Macedonia Północna"}>
                      Macedonia Północna
                    </MenuItem>
                    <MenuItem value={"Malta"}>Malta</MenuItem>
                    <MenuItem value={"Mołdawia"}>Mołdawia</MenuItem>
                    <MenuItem value={"Monako"}>Monako</MenuItem>
                    <MenuItem value={"Niemcy"}>Niemcy</MenuItem>
                    <MenuItem value={"Norwegia"}>Norwegia</MenuItem>
                    <MenuItem value={"Portugalia"}>Portugalia</MenuItem>
                    <MenuItem value={"Rosja"}>Rosja</MenuItem>
                    <MenuItem value={"Rumunia"}>Rumunia</MenuItem>
                    <MenuItem value={"Serbia"}>Serbia</MenuItem>
                    <MenuItem value={"Słowacja"}>Słowacja</MenuItem>
                    <MenuItem value={"Słowenia"}>Słowenia</MenuItem>
                    <MenuItem value={"Szwajcaria"}>Szwajcaria</MenuItem>
                    <MenuItem value={"Turcja"}>Turcja[</MenuItem>
                    <MenuItem value={"Ukraina"}>Ukraina</MenuItem>
                    <MenuItem value={"Węgry"}>Węgry</MenuItem>
                    <MenuItem value={"Wielka Brytania"}>
                      Wielka Brytania
                    </MenuItem>
                    <MenuItem value={"Włochy"}>Włochy</MenuItem>
                    <MenuItem value={"USA"}>USA</MenuItem>
                    <MenuItem value={"Kanada"}>Kanada</MenuItem>
                    <MenuItem value={"Europa"}>Inny - Europa</MenuItem>
                    <MenuItem value={"Azja"}>Inny - Azja</MenuItem>
                    <MenuItem value={"Ameryka Północna"}>
                      Inny - Ameryka Północna
                    </MenuItem>
                    <MenuItem value={"Ameryka Południowa"}>
                      Inny - Ameryka Południowa
                    </MenuItem>
                    <MenuItem value={"Afryka"}>Inny - Afryka</MenuItem>
                    <MenuItem value={"Australia"}>Australia</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="row">
                <TextField
                  id="number"
                  label="Nr. tel"
                  className="input"
                  type="number"
                  inputRef={number}
                  required
                  minLength="8"
                  color="secondary"
                  variant="outlined"
                  style={{ marginRight: "20px" }}
                />
                <TextField
                  id="email"
                  label="Email"
                  required
                  type="email"
                  inputRef={email}
                  className="input"
                  variant="outlined"
                  color="secondary"
                />
              </div>
              <h2>Dane logowania i data urodzenia</h2>
              <div className="row">
                <TextField
                  id="login"
                  label="Login"
                  required
                  minLength="3"
                  inputRef={login}
                  className="input"
                  variant="outlined"
                  color="secondary"
                  style={{ marginRight: "20px" }}
                />
                <TextField
                  id="age"
                  className="input"
                  inputRef={age}
                  required
                  type="date"
                  variant="outlined"
                  color="secondary"
                />
              </div>
              <div className="row">
                <TextField
                  id="password"
                  label="Hasło"
                  inputRef={password}
                  onChange={changeValid}
                  type="password"
                  required
                  minLength="6"
                  className="input"
                  variant="outlined"
                  color="secondary"
                  style={{ marginRight: "20px" }}
                />
                <TextField
                  id="confirmPassword"
                  label="Potwierdź hasło"
                  type="password"
                  inputRef={confirmPassword}
                  onChange={changeValid}
                  required
                  minLength="6"
                  className="input"
                  variant="outlined"
                  color="secondary"
                />
                <p className="validationPassword" ref={validationPassword}>
                  Hasła muszą być takie same
                </p>
                <p
                  className="validationPasswordLength"
                  ref={validationPasswordLength}
                >
                  Hasło musi mieć conajmniej 6 znaków
                </p>
              </div>
              <div className="gender">
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    aria-label="position"
                    name="position"
                    defaultValue={gender}
                  >
                    <FormControlLabel
                      value="man"
                      onChange={handleChangeGender}
                      control={<Radio color="default" />}
                      label="Mężczyzna"
                      labelPlacement="top"
                    />
                    <FormControlLabel
                      value="woman"
                      onChange={handleChangeGender}
                      control={<Radio color="default" />}
                      label="Kobieta"
                      labelPlacement="top"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <div className="checkboxes">
                <FormControlLabel
                  className="label"
                  control={
                    <Checkbox name="checkedB" color="secondary" required />
                  }
                  label={
                    <span className="linklabel">
                      Oświadczam, że zapoznałem się z{" "}
                      <a href="https://www.63dni.pl/polityka-prywatnosci.html">
                        Polityką Prywatności
                      </a>{" "}
                      oraz wyrażam zgodę na przetwarzanie moich danych
                      osobowych, zawartych w powyższym formularzu zgłoszeniowym
                      w celu wykonania czynności związanych z organizacją i
                      realizacją projektu „63 dni! Wyzwanie”
                    </span>
                  }
                />
                <FormControlLabel
                  className="label"
                  control={
                    <Checkbox name="checkedB" color="secondary" required />
                  }
                  label={
                    <span className="linklabel">
                      Oświadczam, że zapoznałem się z{" "}
                      <a href="https://www.63dni.pl/regulamin.html">
                        Regulaminem
                      </a>{" "}
                      projektu „63 dni! Wyzwanie”, w pełni go akceptuję i
                      zobowiązuję się do jego bezwzględnego przestrzegania.
                    </span>
                  }
                />
              </div>
              {/* <ReCAPTCHA
                sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                onChange={onChangeCapatcha}
              /> */}
              <Button
                disabled
                variant="contained"
                color="secondary"
                className="button"
                type="submit"
              >
                Wyzwanie zakończone
              </Button>
            </form>
          </div>
          <div className="left">
            <div className="headline">
              <div className="head"></div>
              <span>
                ZAREJESTRUJ SIĘ TERMINIE <p> OD 20.07 DO 03.10.2021</p>
              </span>
            </div>
            <div className="line">
              <div className="sign"></div>
              <span>
                „63 Dni Wyzwanie” polega na uprawianiu aktywności fizycznej w
                hołdzie Powstańcom Warszawy
              </span>
            </div>
            <div className="line">
              <div className="sign"></div>
              <span>
                Ambicją wyzwania jest wspólne osiągnięcie najwyższego możliwego
                dystansu przez wszystkich uczestników
              </span>
            </div>
            <div className="line">
              <div className="sign"></div>
              <span>
                Rejestracja jest bezpłatna a wyzwanie ma charakter honorowy i
                opiera się na zaufaniu do uczestników
              </span>
            </div>
            <div className="line">
              <div className="sign"></div>
              <span>
                Uczestnik może uprawiać dowolną ilość i rodzaj aktywności
                fizycznej. O miejscu, częstotliwości i rodzaju uprawianej
                aktywności decyduje uczestnik wyzwania
              </span>
            </div>
            <div className="line">
              <div className="sign"></div>
              <span>
                Uczestnicy zobowiązani są zmierzyć pokonany dystans i
                udokumentować to zdjęciowo, czas nie jest istotny
              </span>
            </div>
            <div className="line">
              <div className="sign"></div>
              <span>
                Po rejestracji należy każdorazowo zalogować się do swojego
                profilu uczestnika w celu uzupełnienia dystansu
              </span>
            </div>
            <div className="line">
              <div className="sign"></div>
              <span>
                Uzupełniając dystans należy każdorazowo załączyć „dowodowe
                zdjęcie dystansu”
              </span>
            </div>
            <div className="line">
              <div className="sign"></div>
              <span>
                Odwiedzając swój profil uczestnicy mają możliwość zapoznać się z
                przebiegiem Powstania Warszawskiego dzień po dniu oraz poznać
                sylwetki Powstańców
              </span>
            </div>
            <div className="line">
              <div className="sign"></div>
              <span>
                Wyniki wszystkich zarejestrowanych uczestników są uwzględnione w
                rankingu
              </span>
            </div>
            <div className="line">
              <div className="sign"></div>
              <span>
                Po zakończeniu wyzwania każdy uczestnik otrzyma certyfikat z
                łącznym dystansem pokonanym na przestrzeni 63 dni.
              </span>
            </div>
            {/* <div className="patrons">
              <span>Patronat</span>
              <img src={`${process.env.PUBLIC_URL}/assets/banner.jpg`} alt="" />
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
