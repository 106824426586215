import React, {
  useState,
  useLayoutEffect,
  useEffect,
  useContext,
  useRef,
} from "react";
import axios from "axios";
import RankingUser from "../rankingUser/RankingUser";
import "./ranking.css";
import Swal from "sweetalert2";
import {
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  CircularProgress,
} from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";
import { Context } from "../../context/Context";
const Ranking = ({ initial, profile }) => {
  const { user } = useContext(Context);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [usersS, setUsersS] = useState([]);
  const userSRef = useRef();
  const [sexUsers, setSexUsers] = useState([]);
  const [sortedUsers, setSortedUsers] = useState([]);
  const [totalDistance, setTotalDistance] = useState();
  const [nameD, setNameD] = useState("total");
  const [select, setSelect] = useState("Total");
  const [sexSelect, setSexSelect] = useState("Wszyscy");

  useLayoutEffect(() => {
    const getUsers = async () => {
      let names;
      switch (select) {
        case "Total":
          setNameD("total");
          names = "total";
          break;
        case "Bieg":
          setNameD("bieganie");
          names = "bieganie";
          break;
        case "Chód":
          setNameD("chodzenie");
          names = "chodzenie";
          break;
        case "Jazda na wózku inwalidzkim":
          setNameD("wozek");
          names = "wozek";
          break;
        case "Wioślarstwo":
          setNameD("wioslo");
          names = "wioslo";
          break;
        case "Kajakarstwo":
          setNameD("kajak");
          names = "kajak";
          break;
        case "Jazda na rowerze":
          setNameD("rower");
          names = "rower";
          break;
        case "Jazda na rolkach":
          setNameD("rolki");
          names = "rolki";
          break;
        case "Pływanie":
          setNameD("plywanie");
          names = "plywanie";
          break;
        case "Chód z wózkiem dziecięcym":
          setNameD("dzieckoChod");
          names = "dzieckoChod";
          break;
        case "Bieg z wózkiem dziecięcym":
          setNameD("dzieckoBieg");
          names = "dzieckoBieg";
          break;
        case "Inne":
          setNameD("different");
          names = "different";
          break;
        default:
          setNameD("total");
          names = "total";
      }
      try {
        setLoading(true);
        const res = await axios.get(
          "https://noclegjura.pl/backend/api/users/all"
        );
        setUsers(res.data);
        setSortedUsers(res.data);
        setSexUsers(res.data);
        const sorted = res.data.slice(0, res.data.length).sort((a, b) => {
          if (a[names] > b[names]) return -1;
          if (a[names] < b[names]) return 1;
          return 0;
        });
        setSexSelect("Wszyscy");
        setUsersS(sorted);
        const countList = res.data.map((u) => u.total);
        let count = 0;
        for (let i = 0; i < countList.length; i++) {
          count += countList[i];
        }
        setTotalDistance(count);
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
    getUsers();
  }, [select]);

  const handleChangeSport = (e) => {
    setSelect(e.target.value);
  };
  const handleChangeSex = (e) => {
    setSexSelect(e.target.value);
  };

  let userList;

  const handleReset = () => {
    window.location.reload();
  };

  useEffect(() => {
    if (sexSelect !== "Wszyscy") {
      let users = sexUsers.filter((u) => u.sex === sexSelect);
      users = users.sort((a, b) => {
        if (a[nameD] > b[nameD]) return -1;
        if (a[nameD] < b[nameD]) return 1;
        return 0;
      });
      setUsersS(users);
    } else {
      let users = sexUsers.sort((a, b) => {
        if (a[nameD] > b[nameD]) return -1;
        if (a[nameD] < b[nameD]) return 1;
        return 0;
      });
      setUsersS(users);
    }
  }, [sexSelect, sexUsers, nameD]);

  useEffect(() => {
    const CurrentUserPlace = sortedUsers.findIndex(
      (u) => u.login === user?.login
    );
    window.sessionStorage.setItem("place", CurrentUserPlace + 1);
    const bieganieplaces = sortedUsers.sort((a, b) => b.bieganie - a.bieganie);
    const placeb = bieganieplaces.findIndex((u) => u.login === user?.login);
    window.sessionStorage.setItem("bieganie", placeb);

    const chodzenieplaces = sortedUsers.sort(
      (a, b) => b.chodzenie - a.chodzenie
    );
    const placech = chodzenieplaces.findIndex((u) => u.login === user?.login);
    window.sessionStorage.setItem("chodzenie", placech);

    const plywanieplaces = sortedUsers.sort((a, b) => b.plywanie - a.plywanie);
    const placep = plywanieplaces.findIndex((u) => u.login === user?.login);
    window.sessionStorage.setItem("plywanie", placep);

    const wozekplaces = sortedUsers.sort((a, b) => b.wozek - a.wozek);
    const placew = wozekplaces.findIndex((u) => u.login === user?.login);
    window.sessionStorage.setItem("wozek", placew);

    const wiosloplaces = sortedUsers.sort((a, b) => b.wioslo - a.wioslo);
    const placewio = wiosloplaces.findIndex((u) => u.login === user?.login);
    window.sessionStorage.setItem("wioslo", placewio);

    const kajakplaces = sortedUsers.sort((a, b) => b.kajak - a.kajak);
    const placek = kajakplaces.findIndex((u) => u.login === user?.login);
    window.sessionStorage.setItem("kajak", placek);

    const rowerplaces = sortedUsers.sort((a, b) => b.rower - a.rower);
    const placer = rowerplaces.findIndex((u) => u.login === user?.login);
    window.sessionStorage.setItem("rower", placer);

    const rolkiplaces = sortedUsers.sort((a, b) => b.rolki - a.rolki);
    const placerol = rolkiplaces.findIndex((u) => u.login === user?.login);
    window.sessionStorage.setItem("rolki", placerol);

    const dzieckoChodPlace = sortedUsers.sort(
      (a, b) => b.dzieckoChod - a.dzieckoChod
    );
    const placedzieckoc = dzieckoChodPlace.findIndex(
      (u) => u.login === user?.login
    );
    window.sessionStorage.setItem("dzieckoChod", placedzieckoc);

    const dzieckoBiegPlace = sortedUsers.sort(
      (a, b) => b.dzieckoBieg - a.dzieckoBieg
    );
    const placedzieckob = dzieckoBiegPlace.findIndex(
      (u) => u.login === user?.login
    );
    window.sessionStorage.setItem("dzieckoBieg", placedzieckob);

    const different = sortedUsers.sort((a, b) => b.different - a.different);
    const placediff = different.findIndex((u) => u.login === user?.login);
    window.sessionStorage.setItem("different", placediff);
  }, [sortedUsers, select, user?.login]);

  const handleSortCity = (type, name) => {
    Swal.fire({
      title: "Sortuj ranking",
      html: `<input type="text" id=${type} class="swal2-input" placeholder="${name}...">`,
      confirmButtonText: "Sortuj",
      confirmButtonColor: "#c01820",
      cancelButtonText: "Anuluj",
      showCancelButton: true,
      focusConfirm: false,
      preConfirm: () => {
        const field = Swal.getPopup().querySelector(`#${type}`).value;
        if (!field) {
          Swal.showValidationMessage(`Wypełnij powyższe pole`);
        }
        return { field: field };
      },
    }).then((result) => {
      if (result.value?.field) {
        const getSorted = async () => {
          const sortedType = usersS.filter(
            (u) => u[type].toLowerCase() === result.value.field.toLowerCase()
          );
          const sortedTotal = await sortedType
            .slice(0, usersS.length)
            .sort((a, b) => {
              if (a[nameD] > b[nameD]) return -1;
              if (a[nameD] < b[nameD]) return 1;
              return 0;
            });
          setUsersS(sortedTotal);
          setSortedUsers(sortedTotal);
        };
        getSorted();
      }
    });
  };
  const handleAgeSort = async () => {
    const { value: age } = await Swal.fire({
      title: "Sortuj po wieku",
      input: "select",
      inputOptions: {
        Grupy: {
          old: "Osoby pełnoletnie",
          young: "Osoby niepełnoletnie",
        },
      },
      inputPlaceholder: "Wybierz grupę wiekową",
      confirmButtonColor: "#c01820",
      showCancelButton: true,
      cancelButtonText: "Anuluj",
    });
    if (age === "young") {
      setUsersS(
        usersS.filter(
          (u) => new Date().toISOString().substr(0, 4) - u.age.substr(0, 4) < 18
        )
      );
    }
    if (age === "old") {
      setUsersS(
        usersS.filter(
          (u) =>
            new Date().toISOString().substr(0, 4) - u.age.substr(0, 4) >= 18
        )
      );
    }
  };

  useEffect(() => {
    const getUsers = async () => {
      try {
        await axios("https://noclegjura.pl/backend/api/users/all");
        await axios("https://noclegjura.pl/backend/api/users/all");
        const resaa = await axios(
          "https://noclegjura.pl/backend/api/users/all"
        );
        const sortedTotal = await resaa.data
          .slice(0, resaa.data.length)
          .sort((a, b) => {
            if (a[nameD] > b[nameD]) return -1;
            if (a[nameD] < b[nameD]) return 1;
            return 0;
          });
        userSRef.current = sortedTotal;
        setUsersS(sortedTotal);
      } catch (err) {
        console.log(err);
      }
    };
    getUsers();
  }, [user?.login, users]);

  userList = usersS.map((u, index) => (
    <RankingUser key={u._id} user={u} select={select} index={index} />
  ));

  return (
    <div className="ranking">
      <div className="desc">
        <span>
          Wszystkich uczestników wyzwania:{" "}
          <span style={{ fontWeight: "700" }}>{users?.length}</span>
        </span>
        <p>
          Dystans pokonany przez wszystkich uczestników:{" "}
          <span style={{ fontWeight: "700" }}>
            {String(totalDistance).substr(
              0,
              String(totalDistance)?.indexOf(".") > 0
                ? String(totalDistance)?.indexOf(".") + 3
                : totalDistance
            )}{" "}
            km
          </span>
        </p>
      </div>
      <div className="headline">
        <div className="data">
          <span>#</span>
        </div>
        <div className="data">
          <span>Imię i nazwisko</span>
        </div>
        <div className="data">
          <span>Dystans</span>
        </div>
        <div className="data">
          <span>Miasto</span>
          <ArrowDropDown
            className="dataIcon"
            onClick={() => handleSortCity("city", "Miasto")}
          />
        </div>
        <div className="data">
          <span>Kraj</span>
          <ArrowDropDown
            className="dataIcon"
            onClick={() => handleSortCity("coutry", "Kraj")}
          />
        </div>
        <div className="data">
          <span>Wiek</span>
          <ArrowDropDown className="dataIcon" onClick={handleAgeSort} />
        </div>
        <FormControl className="select">
          <InputLabel id="demo-controlled-open-select-label">
            Dyscyplina
          </InputLabel>
          <Select
            labelId="demo-controlled-open-select-label"
            id="demo-controlled-open-select"
            value={select}
            onChange={handleChangeSport}
          >
            <MenuItem value="Total">Total</MenuItem>
            <MenuItem value="Bieg">Bieg</MenuItem>
            <MenuItem value="Chód">Chód</MenuItem>
            <MenuItem value="Jazda na rolkach">Jazda na rolkach</MenuItem>
            <MenuItem value="Pływanie">Pływanie</MenuItem>
            <MenuItem value="Jazda na rowerze">Jazda na rowerze</MenuItem>
            <MenuItem value="Jazda na wózku inwalidzkim">
              Jazda na wózku inwalidzkim
            </MenuItem>
            <MenuItem value="Wioślarstwo">Wioślarstwo</MenuItem>
            <MenuItem value="Kajakarstwo">Kajakarstwo</MenuItem>
            <MenuItem value="Bieg z wózkiem dziecięcym">
              Bieg z wózkiem dziecięcym
            </MenuItem>
            <MenuItem value="Chód z wózkiem dziecięcym">
              Chód z wózkiem dziecięcym
            </MenuItem>
            <MenuItem value="Inne">Inne</MenuItem>
          </Select>
        </FormControl>
        <FormControl className="selectSex">
          <InputLabel id="demo-controlled-open-select-label">Płeć</InputLabel>
          <Select
            labelId="demo-controlled-open-select-label"
            id="demo-controlled-open-select"
            value={sexSelect}
            onChange={handleChangeSex}
          >
            <MenuItem value="Wszyscy">Wszyscy</MenuItem>
            <MenuItem value="man">Mężczyzna</MenuItem>
            <MenuItem value="woman">Kobieta</MenuItem>
          </Select>
        </FormControl>
        <span className="resetSort" onClick={handleReset}></span>
      </div>
      <div className="winners">
        {sortedUsers.length === 0 ? (
          <span className="notfound">Nie znaleziono</span>
        ) : !loading ? (
          userList
        ) : (
          <div className="loading">
            <CircularProgress color="secondary" className="loadingIcon" />
          </div>
        )}
      </div>
    </div>
  );
};

export default Ranking;
