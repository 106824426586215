import React from "react";
import "./historyitem.css";
import { HighlightOff } from "@material-ui/icons";
import Swal from "sweetalert2";
import axios from "axios";

const HistoryItem = ({ activity, admin }) => {
  const handleDelete = async (e) => {
    e.stopPropagation();
    Swal.fire({
      title: "Usunąć aktywność?",
      showDenyButton: true,
      confirmButtonText: `Usuń`,
      denyButtonText: `Anuluj`,
      denyButtonColor: "#383838",
      confirmButtonColor: "#c01820",
    }).then((result) => {
      if (result.isConfirmed) {
        const deleteActivity = async () => {
          try {
            await axios.delete(
              `https://noclegjura.pl/backend/api/activity/${activity._id}`
            );
          } catch (err) {
            console.log(err);
          }
          window.location.reload();
        };
        deleteActivity();
      }
    });
  };

  const handleClickImage = (e) => {
    e.stopPropagation();
    Swal.fire({
      imageUrl: `${activity.img}`,
      confirmButtonColor: "#c01820",
      imageHeight: 400,
    });
  };

  const handleChangeActivity = (e) => {
    if (admin) {
      Swal.fire({
        title: "Edytuj Aktywność",
        html: `<p>Dystans</p>
        <input type="text" id="km" class="swal2-input" value=${activity.distance}>`,
        confirmButtonText: "Zmień dane",
        confirmButtonColor: "#c01820",
        showCloseButton: true,
        focusConfirm: false,
        preConfirm: () => {
          const km = Swal.getPopup().querySelector("#km").value;
          if (!km) {
            Swal.showValidationMessage(`Wypełnij puste pola`);
          }
          return {
            km,
          };
        },
      }).then((result) => {
        if (result.value?.km) {
          const updateUser = async () => {
            try {
              await axios.put(
                `https://noclegjura.pl/backend/api/activity/${activity._id}`,
                {
                  distance: result.value?.km,
                }
              );
              Swal.fire({
                icon: "success",
                title: "Zapisano zmiany",
                confirmButtonText: `Ok`,
                confirmButtonColor: "#c01820",
                denyButtonColor: "gray",
              }).then(() => {
                window.location.reload();
              });
            } catch (err) {
              console.log(err);
            }
          };
          updateUser();
        }
      });
    }
  };

  return (
    <div className="historyItem" onClick={handleChangeActivity}>
      <div className="place">1</div>
      <div className="personalData">
        <span>{activity.sport}</span>
      </div>
      <div className="distance">
        <span>{activity.distance}km</span>
      </div>
      <div className="city">
        <span>{activity.city}</span>
      </div>
      <div className="country">
        <img src={activity.img} alt="avatar" onClick={handleClickImage} />
      </div>
      <div className="age">
        <span>{activity.createdAt.substr(0, 10)}</span>
      </div>
      <div className="delete">
        <span style={{ display: "flex", alignItems: "center" }}>
          <HighlightOff className="icon" onClick={handleDelete} />
          {admin ? (
            <div
              className="surname"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginLeft: "20px",
                width: "100%",
              }}
            >
              <span>{activity.username}</span>
              <span>{activity.surname}</span>
            </div>
          ) : null}
        </span>
      </div>
    </div>
  );
};

export default HistoryItem;
