import React from "react";
import Ranking from "../../components/ranking/Ranking";
import Topbar from "../../components/Topbar/Topbar";
import './ranking.css'
const ErrorPage = () => {
    return (
        <div>
            <Topbar ranking={true} />
            <div className="rank">
                <Ranking />
            </div>
        </div>
    );
};

export default ErrorPage;
