import React, { useContext, useEffect, useState } from "react";
import "./rankinguser.css";
import Swal from "sweetalert2";
import axios from "axios";
import { Context } from "../../context/Context";
const RankingUser = ({ user, select, index, admin }) => {
  const { user: usera } = useContext(Context);
  const [activities, setActivities] = useState([]);
  let name;
  switch (select) {
    case "Total":
      name = "total";
      break;
    case "Bieg":
      name = "bieganie";
      break;
    case "Chód":
      name = "chodzenie";
      break;
    case "Jazda na wózku inwalidzkim":
      name = "wozek";
      break;
    case "Wioślarstwo":
      name = "wioslo";
      break;
    case "Kajakarstwo":
      name = "kajak";
      break;
    case "Jazda na rowerze":
      name = "rower";
      break;
    case "Jazda na rolkach":
      name = "rolki";
      break;
    case "Pływanie":
      name = "plywanie";
      break;
    case "Chód z wózkiem dziecięcym":
      name = "dzieckoChod";
      break;
    case "Bieg z wózkiem dziecięcym":
      name = "dzieckoBieg";
      break;
    case "Inne":
      name = "different";
      break;
    default:
      name = "total";
  }
  const adult = new Date().toISOString().substr(0, 4) - user.age.substr(0, 4);
  useEffect(() => {
    const getActivity = async () => {
      try {
        const resaa = await axios.post(
          "https://noclegjura.pl/backend/api/activity/user",
          {
            login: user.login,
            role: "user",
          }
        );
        setActivities(resaa.data);
      } catch (err) {
        console.log(err);
      }
    };
    getActivity();
  }, [user.login]);

  useEffect(() => {
    if (activities.length > 0) {
      const total = activities.map((a) => Number(a.distance));
      let score = 0;
      for (let i = 0; i < total.length; i++) {
        score += total[i];
      }

      const bieganie = activities.filter((a) => a.sport === "Bieg");
      const scoreB = bieganie.map((a) => Number(a.distance));
      let scoreBieg = 0;
      for (let i = 0; i < scoreB.length; i++) {
        scoreBieg += scoreB[i];
      }

      const chodzenie = activities.filter((a) => a.sport === "Chód");
      const scoreCh = chodzenie.map((a) => Number(a.distance));
      let scoreChod = 0;
      for (let i = 0; i < scoreCh.length; i++) {
        scoreChod += scoreCh[i];
      }

      const plywanie = activities.filter((a) => a.sport === "Pływanie");
      const scoreply = plywanie.map((a) => Number(a.distance));
      let scorePl = 0;
      for (let i = 0; i < scoreply.length; i++) {
        scorePl += scoreply[i];
      }

      const wozek = activities.filter(
        (a) => a.sport === "Jazda na wózku inwalidzkim"
      );
      const scoreWozek = wozek.map((a) => Number(a.distance));
      let scoreWoz = 0;
      for (let i = 0; i < scoreWozek.length; i++) {
        scoreWoz += scoreWozek[i];
      }

      const kajak = activities.filter((a) => a.sport === "Kajakarstwo");
      const scoreKaj = kajak.map((a) => Number(a.distance));
      let scoreKa = 0;
      for (let i = 0; i < scoreKaj.length; i++) {
        scoreKa += scoreKaj[i];
      }
      const wioslo = activities.filter((a) => a.sport === "Wioślarstwo");
      const scoreWio = wioslo.map((a) => Number(a.distance));
      let scoreW = 0;
      for (let i = 0; i < scoreWio.length; i++) {
        scoreW += scoreWio[i];
      }
      const rower = activities.filter((a) => a.sport === "Jazda na rowerze");
      const scoreRowe = rower.map((a) => Number(a.distance));
      let scoreRo = 0;
      for (let i = 0; i < scoreRowe.length; i++) {
        scoreRo += scoreRowe[i];
      }
      const rolki = activities.filter((a) => a.sport === "Jazda na rolkach");
      const scoreRolk = rolki.map((a) => Number(a.distance));
      let scoreRol = 0;
      for (let i = 0; i < scoreRolk.length; i++) {
        scoreRol += scoreRolk[i];
      }
      const dzieckoBieganie = activities.filter(
        (a) => a.sport === "Bieg z wózkiem dziecięcym"
      );
      const scoreDziecB = dzieckoBieganie.map((a) => Number(a.distance));
      let scoreDziec = 0;
      for (let i = 0; i < scoreDziecB.length; i++) {
        scoreDziec += scoreDziecB[i];
      }
      const dzieckoChodzenie = activities.filter(
        (a) => a.sport === "Chód z wózkiem dziecięcym"
      );
      const scoreDzieckoCh = dzieckoChodzenie.map((a) => Number(a.distance));
      let scoreDziecko = 0;
      for (let i = 0; i < scoreDzieckoCh.length; i++) {
        scoreDziecko += scoreDzieckoCh[i];
      }
      const different = activities.filter((a) => a.sport === "Inne");
      const scoreDiffe = different.map((a) => Number(a.distance));
      let scoreDi = 0;
      for (let i = 0; i < scoreDiffe.length; i++) {
        scoreDi += scoreDiffe[i];
      }
      const updateUser = async () => {
        await axios.put(`https://noclegjura.pl/backend/api/users/${user._id}`, {
          total: score,
          bieganie: scoreBieg,
          chodzenie: scoreChod,
          plywanie: scorePl,
          wozek: scoreWoz,
          kajak: scoreKa,
          wioslo: scoreW,
          rolki: scoreRol,
          rower: scoreRo,
          dzieckoBieg: scoreDziec,
          dzieckoChod: scoreDziecko,
          different: scoreDi,
          role: "user",
        });
      };
      updateUser();
    }
  }, [user._id, user.surname, activities]);

  const handleChangeUser = () => {
    if (admin) {
      Swal.fire({
        title: "Edytuj Profil",
        html: `<input type="text" id="username" class="swal2-input" value=${user.username}>
      <input type="text" id="surname" class="swal2-input" value=${user.surname}>
      <input type="text" id="city" class="swal2-input" value=${user.city}>
      <input type="text" id="coutry" class="swal2-input" value=${user.coutry}>
      <input type="date" id="age" class="swal2-input" value=${user.age}>
      <input type="number" id="number" class="swal2-input" value=${user.number}>
      <input type="email" id="email" class="swal2-input" value=${user.email}>
      `,
        confirmButtonText: "Zmień dane",
        confirmButtonColor: "#c01820",
        showCloseButton: true,
        focusConfirm: false,
        preConfirm: () => {
          const username = Swal.getPopup().querySelector("#username").value;
          const surname = Swal.getPopup().querySelector("#surname").value;
          const city = Swal.getPopup().querySelector("#city").value;
          const age = Swal.getPopup().querySelector("#age").value;
          const coutry = Swal.getPopup().querySelector("#coutry").value;
          const number = Swal.getPopup().querySelector("#number").value;
          const email = Swal.getPopup().querySelector("#email").value;
          if (
            !username ||
            !surname ||
            !city ||
            !age ||
            !coutry ||
            !number ||
            !email
          ) {
            Swal.showValidationMessage(`Wypełnij puste pola`);
          }
          return {
            username,
            surname,
            city,
            coutry,
            age,
            number,
            email,
          };
        },
      }).then((result) => {
        if (result.value?.username) {
          const updateUser = async () => {
            try {
              await axios.put(
                `https://noclegjura.pl/backend/api/users/${user._id}`,
                {
                  username: result.value?.username,
                  surname: result.value?.surname,
                  city: result.value?.city,
                  age: result.value?.age,
                  coutry: result.value?.coutry,
                  number: result.value?.number,
                  email: result.value?.email,
                  role: "user",
                }
              );
              Swal.fire({
                icon: "success",
                title: "Zapisano zmiany",
                confirmButtonText: `Ok`,
                confirmButtonColor: "#c01820",
                denyButtonColor: "gray",
              }).then(() => {
                window.location.reload();
              });
            } catch (err) {
              console.log(err);
            }
          };
          updateUser();
        }
      });
    }
  };
  return (
    <div
      className="user"
      style={
        usera?.login === user?.login
          ? { backgroundColor: "#ffb9bd" }
          : {} && admin
          ? { cursor: "pointer" }
          : {}
      }
      onClick={handleChangeUser}
    >
      <div className="place">{index + 1}</div>
      <div className="personalData">
        <img
          src={
            user.img
              ? user.img
              : `${process.env.PUBLIC_URL}/assets/63dni_${user.sex}.jpg`
          }
          alt="avatar"
        />
        <span>
          {user.username} {user.surname}
        </span>
      </div>
      <div
        className="distance"
        style={
          admin
            ? {
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }
            : {}
        }
      >
        <span>
          {admin
            ? user.number
            : String(user[name]).substr(
                0,
                String(user[name])?.indexOf(".") > 0
                  ? String(user[name])?.indexOf(".") + 3
                  : user[name]?.length
              )}{" "}
          {admin ? "" : "km"}
        </span>
        {admin ? <span style={{ fontSize: "12px" }}>{user.email}</span> : null}
      </div>
      <div className="city">
        <span>{user.city}</span>
      </div>
      <div className="country">
        <span>{user.coutry}</span>
      </div>
      <div className="age">
        {admin ? (
          <span>{adult}</span>
        ) : (
          <span>{adult > 18 ? ">18" : "<18"}</span>
        )}
      </div>
    </div>
  );
};

export default RankingUser;
